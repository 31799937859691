"use strict";

/*-----------------------------------------------
|   Data table
-----------------------------------------------*/
const dataTableInit = () => {
  // $.fn.dataTable.ext.classes.sPageButton = 'btn btn-falcon-default btn-sm';
    const dataTables = $(".data-table");
    $(document).ready(function() {
        const customDataTable = (elem) => {
          elem.find(".pagination").addClass("pagination-sm");
          //   .closest('[class*="col"]')
          //   .removeClass('col-sm-12 col-md-7')
          //   .addClass('col-auto mt-2 px-1')
          //   .closest('.row')
          //   .addClass('no-gutters justify-content-center justify-content-md-between px-3 pb-3');
        };
        dataTables.length &&
            dataTables.each((index, value) => {
                const $this = $(value);
                $this.DataTable({
                    pageResize: true,
                    //responsive: true,
                    pageLength: 10,
                    info: true,
                    lengthChange: false,
                    sWrapper: "falcon-data-table-wrapper",
                    serverSide: false
                    //scrollX: true
                });
                const $wrpper = $this.closest(".dataTables_wrapper");
                customDataTable($wrpper);
                $this.on("draw.dt", () => customDataTable($wrpper));
                //$(".pagination").addClass("pagination-sm");
            });
    });

};

export default dataTableInit;