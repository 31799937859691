/* -------------------------------------------------------------------------- */
/*                             Overlay Scrollbars                             */
/* -------------------------------------------------------------------------- */
const simplebarInit = () => {
  Array.prototype.forEach.call(
    document.querySelectorAll(".scrollbar"),
    (el) => {
      el.classList.add('overflow-auto');
      return new window.SimpleBar(el, {
        timeout: 200
      });
    }
  );
};

export default simplebarInit;
